<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        생활연수 신청
      </div>
      <!-- //header-title -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility">
    <!-- main-content -->
    <div class="main-content">
      <!-- content-section: 이미지 슬라이더 -->
      <section class="content-section-wrap">
        <ImageSwiperMobileComponent :image-list="ptArea"/>
      </section>

      <!-- content-section:연수원 -->
      <section class="content-section-wrap">
        <div class="section-divider">
          <div class="divider"></div>
        </div>
        <header class="section-header">
          <h4 class="title">체육시설</h4>
        </header>
        <div class="section-contents side-pd">
          <div class="kb-btn-actions">
            <button class="kb-btn-facility" :class="{ 'is-active' : fcltDivCdDcd == '2094001' }" @click="selectFacilities('2094001')">일산 운동장</button>
<!--            <button class="kb-btn-facility is-active">일산 운동장</button>-->
          </div>
        </div>
      </section>

      <!-- content-section:투숙기간 -->
      <section class="content-section-wrap">
        <div class="section-divider">
          <div class="divider"></div>
        </div>
        <header class="section-header">
          <h4 class="title">예약일시</h4>
        </header>
        <div class="section-contents side-pd">
          <div class="kb-btn-actions">
            <button class="kb-btn-facility" :class="{ 'is-active' : reserveDateTime }" @click="openSelectReservation">{{ getSelectPeriodTxt() }}</button>
          </div>
        </div>
      </section>
      <section class="content-section-wrap">
        <div class="section-divider">
          <div class="divider"></div>
        </div>
        <div class="page-buttons">
          <button class="kb-btn kb-btn-primary" :disabled="!nextCheck" @click="nextStep">검색</button>
        </div>
      </section>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>
<script>
import {computed, ref} from 'vue';
import {useStore} from "vuex";
import { timestampToDateFormat} from "@/assets/js/util";

import ImageSwiperMobileComponent from '@/components/daily/mobile/ImageSwiperMobileComponent';
import {useRouter} from "vue-router";
import {ACT_SET_FCLT_APLY_MOBILE_INFO} from "@/store/modules/trnct/trnct";


export default {
  name: 'DailyMobileSportsApplyStep0',
  components: {
    ImageSwiperMobileComponent
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const session = computed(() => store.state.auth.session);
    const nextCheck = computed(() => store.state.trnct.mobileSportsApplyInfo.reserveDateTime == null ? false : true);
    const reserveDateTime = computed(() => store.state.trnct.mobileSportsApplyInfo.reserveDateTime);

    const ptArea = ref([
      {
        src: require('../../../../assets/lxp/images/daily/facility.jpg'),
        title: '이미지1',
      }
    ]);

    const fcltDivCdDcd = ref(store.state.trnct.mobileSportsApplyInfo.fcltDivCdDcd);
    const reserveVenue = ref('체육시설');

    const openSelectPeriod = () => {
      router.push('/daily/apply/step0/date');
    }

    const getSelectPeriodTxt = () => {
      if(store.state.trnct.mobileSportsApplyInfo.reserveDateTime){
        return `${store.state.trnct.mobileSportsApplyInfo.reserveDate} ${store.state.trnct.mobileSportsApplyInfo.reserveDateTime.time}`;
      }else{
        return '예약일시';
      }
    }

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const selectFacilities = (code) => {
      fcltDivCdDcd.value = code;
    }

    const nextStep = () => {
      store.commit(`trnct/${ACT_SET_FCLT_APLY_MOBILE_INFO}`, {  fcltDivCdDcd: fcltDivCdDcd.value});

      router.push('/daily/apply/sports/step1');
    }

    const openSelectReservation = () => {
      router.push('/daily/apply/sports/step0/date');
    }

    return {
      ptArea,
      fcltDivCdDcd,
      reserveVenue,
      session,
      reserveDateTime,
      openSelectPeriod,
      getSelectPeriodTxt,
      getDateFormat,
      nextCheck,
      nextStep,
      openSelectReservation,
      selectFacilities
    };
  },
};
</script>
